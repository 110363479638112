import React, { useContext, useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import HeroCarousel from "../components/organisms/Homepage/HeroCarousel/HeroCarousel"
import tw from "twin.macro"
import { Pixlee } from "../components/molecules/Pixlee"
import { SeriesSelection } from "../components/organisms/Homepage"
import { FeaturedCallout } from "../components/organisms/Homepage/FeaturedCallout"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { DisclaimersContext } from "../contexts/Disclaimers"
import useSalesEventsData from "../hooks/useSalesEventsData"
import SalesEventHero from "../components/organisms/Homepage/SalesEventHero/SalesEventHero"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { ImageBlocks } from "../components/organisms/Series"

const HomePage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  console.log(page)

  const { handleTealView } = useTealiumView()
  const [state, dispatch] = useContext(DisclaimersContext)

  const salesEvent = useSalesEventsData()[0]
  const [salesEventLoaded, setSalesEventLoaded] = useState(false)
  const [hasActiveSalesEvent, setHasActiveSalesEvent] = useState(false)

  useEffect(() => {
    if (salesEvent && salesEvent.salesEventActive) {
      setHasActiveSalesEvent(true)
    }
    setSalesEventLoaded(true)
  }, [salesEvent])

  useEffect(() => {
    handleTealView({
      page_name: "homepage",
      page_type: "homepage",
      tealium_event: "homepage",
    })
  }, [])
  return (
    <Layout tealium={{ label: "homePage" }}>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <h1 css={[tw`sr-only`]}>Get to Know Our Family of Vehicles</h1>
      <SanitySections
        sections={page?.content}
        salesEventLoaded={salesEventLoaded}
        hasActiveSalesEvent={hasActiveSalesEvent}
        salesEvent={salesEvent}
      />
    </Layout>
  )
}

const SanitySections: React.FC<{
  sections: any
  salesEventLoaded: boolean
  hasActiveSalesEvent: boolean
  salesEvent: any
}> = ({
  sections,
  salesEventLoaded,
  hasActiveSalesEvent,
  salesEvent,
}): JSX.Element => {
  const [slideIndex, setSlideIndex] = useState(0)
  return (
    <>
      {sections?.map((section: any) => {
        switch (section?._type) {
          case "heroCarousel":
            // // Filtering out Retention SE slides associated with an inactive SE
            // section.slides = section?.slides?.filter(slide => {
            //   if (
            //     slide?._type === "retentionSEHeroItem" &&
            //     !slide?.salesEvent?.salesEventActive
            //   ) {
            //     return false
            //   } else {
            //     return true
            //   }
            // })
            if (salesEventLoaded) {
              if (hasActiveSalesEvent) {
                const { ...slides } = section.slides
                const { vehicleRef, mainImage, mainImageMobile, ctaArray } =
                  slides[slideIndex]

                return (
                  <SalesEventHero
                    salesEvent={salesEvent}
                    series={vehicleRef?.series.name.toLowerCase()}
                    mainImage={mainImage}
                    desktopImage={mainImage}
                    mainImageAlt={"imageAlt"}
                    mainImageMobile={mainImageMobile}
                    mainImageMobileAlt={"imageAlt"}
                    vehicle={vehicleRef}
                    background={salesEvent?.eventImageBackground?.image}
                    backgroundMobile={
                      salesEvent?.eventImageBackgroundMobile?.image
                    }
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    slideCount={Object.keys(slides).length}
                    ctaArray={ctaArray}
                    eventForefrontImage={salesEvent?.eventForefrontImage}
                    eventVideo={salesEvent?.eventVideo || null}
                    eventVideoMobile={salesEvent?.eventVideoMobile || null}
                    eventVideoPoster={salesEvent?.eventVideoPoster}
                    eventVideoPosterMobile={salesEvent?.eventVideoPosterMobile}
                    slideData={slides[slideIndex]}
                    disclaimers={slides[slideIndex]?.disclaimers || []}
                  />
                )
              } else {
                return <HeroCarousel {...section} />
              }
            } else {
              return (
                <div
                  css={[
                    tw`w-full bg-white`,
                    tw`h-[670px]`,
                    tw`sm:(h-[550px])`,
                    tw`md:(h-[700px])`,
                    tw`lg:(h-[600px])`,
                  ]}
                ></div>
              )
            }
          case "imageWithText":
            return <FeaturedCallout {...section} />
          case "pixleeAlbum":
            return <Pixlee {...section} />
          case "imageBlocks":
            const imageBlocksData = section?.callouts
            return (
              <ImageBlocks
                imageBlocks={imageBlocksData}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "",
                }}
              />
            )
          case "imageBlocksWithDescriptionSection":
            return (
              <ImageBlocksWithDescription
                section={section}
                tealCarouselLabel="other pages"
                analyticsIdArrows="other pages:other pages"
              />
            )
          case "seriesSelection":
            return <SeriesSelection {...section} tealIncludeLabel={true} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query HomePageQuery($id: String) {
    page: sanityPageHome(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        synonyms
        description
        canonicalPath
      }
      published
      internal {
        content
        contentDigest
        description
        fieldOwners
        ignoreType
        mediaType
        owner
        type
      }
      content {
        ...HeroCarouselData
        ...ImageBlocksData
        ...ImageBlocksWithDescriptionData
        ...FeaturedCalloutData
        ...PixleeSectionData
        ... on SanityPixleeAlbum {
          _key
          _type
          pixleeHeading
          pixleeIntroText
        }
        ...SeriesSelectionData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default HomePage
